import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import TitleChecked from "../components/TitleChecked"
import { PageContainer } from "../components/PageContainer/styles";
import BannerInfo from "../components/BannerInfo";
import { Link } from "gatsby"
import { theme } from "../theme"

const IndexPage = () => {

  return (<Layout>
    <SEO 
      title="Obbiettivi di Impresa Raf Service"
      description="Impresa Raf Service è un'impresa di pulizie e servizi per l'igiene ambientale con sede a Milano. RAF SERVICE esegue anche la cura di aree verdi interne ed esterne, la manutenzione straordinaria di campi da calcio in erba e sintetici, l'installazione di impianti di irrigazione a scomparsa."
    />
    <PageContainer>
      <Container>
        <Row>
          <Col xs={12} style={{ marginBottom: theme.spacing.betweenRows }}>
            <TitleChecked title="Anni di esperienza, un solo obiettivo: la completa soddisfazione del cliente" />
            <BannerInfo>
              <p>Vi invitiamo a <Link to="/contatti">contattarci richiedendo un preventivo gratuito e senza alcun impegno</Link>. Potrete così "testare" la nostra competenza, la cura con la quale effettuiamo gli interventi e la professionalità dei nostri addetti. Siamo certi che <strong>RAF SERVICE</strong> si rivelerà il partner ideale per tutte le vostre esigenze di pulizia.</p>
            </BannerInfo>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TitleChecked title="Come lavoriamo? Chiedetelo a loro..." />
            <p style={{ marginBottom: '20px' }}>Di seguito alcune delle referenze di <strong>RAF SERVICE</strong>:</p>
            <ul><li><strong>Uffici</strong></li></ul>
            <p className="ref-list-name">Sevecom Srl, Milano</p>
            <p className="ref-list-name">Sevefood Srl, Milano</p>
            <p className="ref-list-name">Fimac SPA, Senago</p>
            <p className="ref-list-name">GR Sports, Milano</p>
            <p className="ref-list-name">Techno Drive, Arluno MI</p>
            <br />
            <ul><li><strong>Negozi</strong></li></ul>
            <p className="ref-list-name">Simonetta, Milano</p>
            <p className="ref-list-name">Annarita N, Milano</p>
            <p className="ref-list-name">Martinelli, Milano</p>
            <br />
            <ul><li><strong>Condomini</strong></li></ul>
            <p className="ref-list-name">Condominio Via Veregoni 5, S. Stefano Ticino MI</p>
            <p className="ref-list-name">Condominio Via Verdi, S. Stefano Ticino MI</p>
            <p className="ref-list-name">Condominio Via Azalee, S. Stefano Ticino MI</p>
            <p className="ref-list-name">Condominio Via Trento, Corbetta MI</p>
            <p className="ref-list-name">Condominio Via Pellico, Corbetta MI</p>
            <br />
            <ul><li><strong>Centri Sportivi</strong></li></ul>
            <p className="ref-list-name">Vismara AC MIlan, Milano</p>
            <p className="ref-list-name">Cimiano Calcio, Milano</p>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  </Layout>)
}

export default IndexPage
